// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")

import "../../stylesheets/members/application"

import "bootstrap"
// デバック用
console.log('会員向け画面')

import '../../controllers'

import '../../../assets/javascripts/serviceworker-companion.js';

/**
 * Import a2hs.js
 */
import AddToHomeScreen from "a2hs.js";

/**
 * Advanced (with your config) init
 */

document.addEventListener("turbolinks:load", function () {
  AddToHomeScreen({
    brandName: "葵WEB会員",
    htmlContent: "<strong>葵WEB会員アプリ</strong>に簡単にアクセスできるように、<strong>ホーム画面に追加</strong>しましょう。↓",
    logoImage: '<img src="/icons/favicon.ico">'
  });
},{ once: true })

$(document).on('turbolinks:load', function(){
  //　スライダーの実装
  $('.slider').slick({
    dots: true,
    arrows: false
  });

  //　スライダーの実装
  $('.shop_slider').slick({
    dots: true,
    arrows: true
  });

  $('.slick-arrow').text('')
  //　お知らせクリックで削除、4秒後に削除を実装
  $('.notice').on('click', (function() {
    $('.notice').fadeOut('slow')
  }));

  setTimeout("$('.notice').fadeOut('slow')", 2000)

  $('.alert').on('click', (function() {
    $('.alert').fadeOut('slow')
  }));

  setTimeout("$('.alert').fadeOut('slow')", 2000)

  if ( $('body').hasClass('home') ) {
    setTimeout("$('.home').addClass('a2hs__container__none');", 3500)
  } else {
    $('.a2hs__container').css('display','none');
  }

  //var shop_link_marginbottom = $('#shop-link').css('margin-bottom')
  var bonus_link = $('#bonus-link').height() + 25;
  $('#shop-link').css('margin-bottom', bonus_link);

  var loader = $('#loader-wrap');

  //ページの読み込みが完了したらアニメーションを非表示
  $(window).on('load', function() {
    loader.fadeOut();
  });
  //ページの読み込みが完了してなくても2秒後にアニメーションを非表示にする
  setTimeout(function(){
    loader.fadeOut();
  },2000);

  $('#rotate-button').on('click', (function() {
    $('.bonus__show').toggleClass('rotate');
    $('#confirmationModal').toggleClass('rotate');
  }));
});

$(document).on('turbolinks:render', function(){
  var loader = $('#loader-wrap');

  loader.fadeOut();
  //ページの読み込みが完了してなくても2秒後にアニメーションを非表示にする
  setTimeout(function(){
    loader.fadeOut();
  },2000);
})