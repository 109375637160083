import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["postalCode", "prefectureCode", "address"]

  connect() {
    // デバック
    console.log('maps_controller.js')
  }

  getAddress(event) {
    event.preventDefault()

    const api = "https://maps.googleapis.com/maps/api/geocode/json"
    const key = this.data.get("key")
    const postalCode = this.postalCodeTarget.value
    const url = api + "?key=" + key + "&address=" + postalCode + "&language=ja"

    // 郵便番号が7桁未満ならAPIを実行しないようにする
    if (postalCode.length < 7) {
      return false;
    }

    fetch(url)
      .then(function (response) {
        if (response.status !== 200) {
          throw '情報が取得できませんでした';
        }
        return response.json();
      })
      .then(data => {
        if (data.status == "OK") {
          let address = data.results[0].address_components
          // '日本'を削除
          address.shift()
          // 住所を逆ソート
          address = address.reverse()
          // '郵便番号'を削除
          address.shift()

          // 住所のテキストを抜き出し
          address = address.map(data => {
            return data.long_name
          })

          // 都道府県を抜き出し
          const prefecture = address.shift()

          this.prefectureCodeTarget.value = this.nameToCode(prefecture)
          this.addressTarget.value = address.join('')
        } else if (data.status == "ZERO_RESULTS") {
          throw '該当する住所が見つかりませんでした';
        }
      })
      .catch(function (error) {
        alert(error)
      });
  }

  nameToCode(name) {
    const prefectures = {
      "北海道": 1,
      "青森県": 2,
      "岩手県": 3,
      "宮城県": 4,
      "秋田県": 5,
      "山形県": 6,
      "福島県": 7,
      "茨城県": 8,
      "栃木県": 9,
      "群馬県": 10,
      "埼玉県": 11,
      "千葉県": 12,
      "東京都": 13,
      "神奈川県": 14,
      "新潟県": 15,
      "富山県": 16,
      "石川県": 17,
      "福井県": 18,
      "山梨県": 19,
      "長野県": 20,
      "岐阜県": 21,
      "静岡県": 22,
      "愛知県": 23,
      "三重県": 24,
      "滋賀県": 25,
      "京都府": 26,
      "大阪府": 27,
      "兵庫県": 28,
      "奈良県": 29,
      "和歌山県": 30,
      "鳥取県": 31,
      "島根県": 32,
      "岡山県": 33,
      "広島県": 34,
      "山口県": 35,
      "徳島県": 36,
      "香川県": 37,
      "愛媛県": 38,
      "高知県": 39,
      "福岡県": 40,
      "佐賀県": 41,
      "長崎県": 42,
      "熊本県": 43,
      "大分県": 44,
      "宮崎県": 45,
      "鹿児島県": 46,
      "沖縄県": 47
    }
    return prefectures[name]
  }
}

